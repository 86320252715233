import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NewsAPI } from "api/model";
import newsApi from "api/newsApi";
import { INewspaper } from "pages/interface";

const initialState: {
  newsData: INewspaper[];
  currentPage: number;
  isMaxPage: boolean;
  currentFilter: string;
} = {
  newsData: [],
  currentPage: 1,
  isMaxPage: false,
  currentFilter: "",
};

export const getNews = createAsyncThunk("home/news", async (data: NewsAPI) => {
  const res = await newsApi.getNews(data);
  return res;
});

const home = createSlice({
  name: "home",
  initialState,
  reducers: {
    handleResetNews: (state) => {
      return {
        ...initialState,
        currentFilter: state.currentFilter,
      };
    },
    handlePage: (state, action) => {
      state.currentPage += action.payload;
    },
    handleMaxPage: (state) => {
      state.isMaxPage = true;
    },
    handleSetFilter: (state, action) => {
      const filterValue = action.payload;

      if (filterValue === "/") {
        state.currentFilter = "";
      } else state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action: PayloadAction<any>) => {
      const { collection } = action.payload.data;

      if (collection.length > 0) {
        state.newsData = [...state.newsData, ...collection];
      }
    });
  },
});

const { reducer, actions } = home;
export const { handleResetNews, handlePage, handleMaxPage, handleSetFilter } =
  actions;
export default reducer;
