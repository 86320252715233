import axiosClient from "./axiosClient";
import { NewsAPI } from "./model";

const newsApi = {
  getNews: ({ params, signal }: NewsAPI) => {
    const url = "/api/news";
    return axiosClient.get(url, { params, signal });
  },
  getNewsDetail: (id: string) => {
    const url = `/api/news/${id}`;
    return axiosClient.get(url);
  },
};

export default newsApi;
