export const enum PageUrl {
  ROOT = "/",
  HOMEPAGE = "/home",
  NEWS_DETAIL = "/newspaper/:id",
  NEWS_ROOT = "/newspaper",
  ALL = "*",
}

export const enum PageSearchParams {
  FILTER = "filterBy",
}
