const enviromentConfig = {
  development: {
    endPoint: "https://gateway.dev.meu-solutions.com/newspapers",
  },
  production: {
    endPoint: "https://gateway.dev.meu-solutions.com/newspapers",
  },
};

export default enviromentConfig;
